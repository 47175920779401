import { connector } from './connector';

export async function GET_STUDENTS(term_id, classrooms, school_id, callback, onError) {
	if (classrooms && term_id) {
		var out = `${classrooms.join("', '")}`;
		try {
			let url = `/students?classrooms='${out}'&term_id=${term_id}&school_id=${school_id}`;
			let res = await connector.get(`${url}`);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_STUDENTS_DETAILS(term, classrooms, callback, onError) {
	if (classrooms && term) {
		// var out = `${classrooms.join("', '")}`;
		try {
			// let url = `/students?classrooms='${out}'&term_id=${term}`;
			let url = `/students/details?classroom=${classrooms}&term_id=${term}`;
			let res = await connector.get(`${url}`);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function SEARCH_STUDENT(searchTerm, callback, onError) {
	try {
		let url = `/students/search/${searchTerm}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_STUDENT(student_id, callback, onError) {
	if (student_id) {
		try {
			let url = `/students/${student_id}`;
			let res = await connector.get(`${url}`);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_STUDENT_HISTORY(student_id, callback, onError) {
	if (student_id) {
		try {
			let url = `/students/${student_id}/history`;
			let res = await connector.get(`${url}`);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function POST_STUDENT(payload, callback, onError) {
	if (payload) {
		try {
			let url = `/students`;
			let res = await connector.post(`${url}`, payload);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function POST_PASSPORT(passport, student_id, callback, onError) {
	if (passport) {
		try {
			let uploadPassport = { passport: passport };
			let url = `/students/passport/${student_id}`;
			let res = await connector.post(`${url}`, uploadPassport);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function DELETE_STUDENT_PASSPORT(student_id, callback, onError) {
	if (student_id) {
		try {
			let url = `/students/passport/${student_id}`;
			let res = await connector.delete(`${url}`);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function PUT_STUDENT(payload, callback, onError) {
	if (payload) {
		try {
			let url = `/students`;
			let res = await connector.put(`${url}`, payload);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function DELETE_STUDENTS(students, term, callback, onError) {
	let payload = { student_id: students, term_id: term };
	try {
		let url = `/students`;
		let res = await connector.delete(`${url}`, { data: payload });
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_EXCEL_DUMP_STUDENTS(payload, callback, onError) {
	try {
		let url = `/students/dump-excel`;
		let res = await connector.post(`${url}`, payload);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_MOVE_STUDENTS(
	sourceClass,
	destClass,
	sourceTerm,
	destTerm,
	school_id,
	callback,
	onError
) {
	let payload = {
		source_classroom_id: sourceClass,
		dest_classroom_id: destClass,
		source_term_id: sourceTerm,
		dest_term_id: destTerm
	};
	try {
		let url = `/students/import/filter?school_id=${school_id}`;
		let res = await connector.post(`${url}`, payload);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_MOVE_STUDENTS(
	sourceClass,
	destClass,
	sourceTerm,
	destTerm,
	moveWithAssets,
	students,
	school_id,
	callback,
	onError
) {
	let payload = {
		source_classroom: sourceClass,
		dest_classroom: destClass,
		source_term: sourceTerm,
		dest_term: destTerm,
		move_with_assets: moveWithAssets,
		students: students

		// 	"source_classroom_id": "1",
		// "dest_classroom_id": "5",
		//   "source_term_id": "6",
		// "dest_term_id": "6"
	};
	try {
		let url = `/students/dump-import?school_id=${school_id}`;
		let res = await connector.post(`${url}`, payload);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function REMOVE_LEAKED_DATA(callback, onError) {
	try {
		let url = `/students/remove-leaked-data`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_PARENT_STUDENTS(school_id, callback, onError) {
	try {
		let url = `/parent/${school_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_STUDENT_RESOURCES(student_id, callback, onError) {
	try {
		let url = `/parent/${student_id}/resources`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}
